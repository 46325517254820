import { required, number, email, regex } from 'react-admin'

export const validation = {
  required: [required()],
  // email: [required(), email()],
  email: [email()],
  phone: [regex(/^([+\d].*)?\d$/, 'Invalid phone number')],
  number: [required(), number()],
  pib: [number()]
}
