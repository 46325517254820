import { Container, Section } from 'components/shared/Fields'
import { ImageUpload } from 'components/shared/ImageUpload'
import { deleteImage, uploadImageToAlbum } from 'esnafDataProvider'
import React from 'react'
import {
  TextField,
  Edit,
  SimpleForm,
  TextInput,
  useEditController
} from 'react-admin'

const AlbumEdit: React.FC<any> = props => {
  const { record } = useEditController(props)

  return (
    <Edit
      undoable={false}
      title="Edit album"
      transform={data => {
        const formData = new FormData()

        formData.append('albumId', data.id.toString())
        data?.coverImage && formData.append('image', data.coverImage)
        formData.append('name', data.name)

        return formData
      }}
      {...props}
    >
      <SimpleForm>
        <Container>
          <Section label="General">
            <TextField source="id" />
            <TextInput source="name" />
          </Section>
          <Section label="Cover image">
            <ImageUpload
              source="coverImage"
              previewSource={record?.coverImageUrl}
              // onChange={async file => {
              //   await uploadImageToAlbum({
              //     albumId: record.id,
              //     image: file,
              //     imageName: '' //add real image name
              //   })
              // }}
            />
          </Section>

          <Section label="Images">
            {record?.images.map(image => {
              return (
                <ImageUpload
                  key={image.id}
                  source={`logo ${image.id}`}
                  onChange={async file => {
                    await deleteImage(image.id)
                    await uploadImageToAlbum({
                      albumId: record.id,
                      image: file,
                      imageName: file?.title.split('.')[0]
                    })
                  }}
                  previewSource={image.url}
                />
              )
            })}
            {/* <ImageUpload source="image" multiple /> */}
          </Section>
        </Container>
      </SimpleForm>
    </Edit>
  )
}

export default AlbumEdit
