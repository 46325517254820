import axios from 'axios'
import { uniqueId } from 'lodash'

export const apiUrl = process.env.REACT_APP_API_URL

const user = JSON.parse(localStorage.getItem('user'))

export const http = axios.create({
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Upgrade-Insecure-Requests': 1,
    Authorization: user?.accessToken
  }
})

const getResource = (resource, endpoint) => {
  const securedResources = ['album']
  const securedEndpoints = ['update', 'save', 'deleteById']

  if (
    securedEndpoints.includes(endpoint) ||
    securedResources.includes(resource)
  ) {
    return `${apiUrl}/${resource}/scr/${endpoint}`
  }
  return `${apiUrl}/${resource}/${endpoint}`
}

const dataProvider: any = {
  getList: (resource, params) =>
    http
      .get<any>(getResource(resource, 'findAll'), {
        params: {
          page: params.pagination.page - 1,
          size: params.pagination.perPage,
          sort: `${params.sort.field},${params.sort.order}`
        }
      })
      .then(({ data }) => {
        if (data?.content) {
          return {
            data: data.content,
            total: data.totalElements
          }
        }
        return {
          data: data.map((item, index) => ({
            ...item,
            id: item?.id || index
          })),
          total: data.length
        }
      }),
  getOne: (resource, params) =>
    http
      .get<any>(getResource(resource, 'findById'), { params })
      .then(response => ({
        data: { ...response.data }
      })),

  update: (resource, params) =>
    http
      .put<any>(getResource(resource, 'update'), params.data)
      .then(response => ({ data: { ...response.data } })),

  create: (resource, params) =>
    http
      .post<any>(getResource(resource, 'save'), params.data)
      .then(response => ({
        data: {
          ...response.data,
          id: response.data.id ? response.data.id : uniqueId()
        }
      })),
  delete: (resource, params) =>
    http.delete<any>(getResource(resource, 'deleteById'), {
      params: { id: params.id }
    }),
  getMany: resource =>
    http.get<any>(getResource(resource, 'findAll')).then(({ data }) => ({
      data: data.content
    }))
  // getManyReference: () => Promise.resolve(),
  // updateMany: () => Promise.resolve(),
  // deleteMany: () => Promise.resolve()
}
export const uploadProductImage: any = async ({
  productId,
  file,
  imageType = 'cover'
}: {
  productId: string
  file: File
  imageType?: 'cover' | 'pictogram'
}) => {
  if (file) {
    const formData = new FormData()

    formData.append('productId', productId)
    formData.append('image', file)
    return http
      .post<any>(`${apiUrl}/product/scr/${imageType}`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}
export const uploadServiceImage: any = async ({
  serviceId,
  file
}: {
  serviceId: string
  file: File
}) => {
  if (file) {
    const formData = new FormData()

    formData.append('serviceId', serviceId)
    formData.append('image', file)
    return http
      .post<any>(`${apiUrl}/service/scr/cover`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}
interface UploadLogoProps {
  companyId: string
  file: File
}
export const uploadLogo: any = async ({ companyId, file }: UploadLogoProps) => {
  if (file) {
    const formData = new FormData()

    formData.append('companyId', companyId)
    formData.append('image', file)
    return http
      .post<any>(`${apiUrl}/company/scr/uploadLogo`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}
export const uploadThumbnail: any = async ({
  companyId,
  file
}: UploadLogoProps) => {
  if (file) {
    const formData = new FormData()

    formData.append('companyId', companyId)
    formData.append('image', file)

    return http
      .post<any>(`${apiUrl}/company/scr/uploadThumbnail`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

interface UploadCompanyAlbumProps {
  companyId: string
  image: File
  name: string
}
export const uploadCompanyAlbum: any = async ({
  companyId,
  image,
  name
}: UploadCompanyAlbumProps) => {
  if (image) {
    const formData = new FormData()

    formData.append('companyId', companyId)
    formData.append('image', image)
    formData.append('name', name)

    return http
      .post<any>(`${apiUrl}/album/scr/companyAlbum`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

interface UploadProductAlbumProps {
  productId: string
  image: File
  name: string
  isComparison: boolean
  is360: boolean
}
export const uploadProductAlbum: any = async ({
  productId,
  image,
  name,
  isComparison = false,
  is360 = false
}: UploadProductAlbumProps) => {
  if (image) {
    const formData = new FormData()

    formData.append('productId', productId)
    formData.append('image', image)
    formData.append('name', name)
    formData.append('isComparison', isComparison.toString())
    formData.append('is360', is360.toString())

    return http
      .post<any>(`${apiUrl}/album/scr/productAlbum`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

interface UploadServiceAlbumProps {
  serviceId: string
  image: File
  name: string
}
export const uploadServiceAlbum: any = async ({
  serviceId,
  image,
  name
}: UploadServiceAlbumProps) => {
  if (image) {
    const formData = new FormData()

    formData.append('serviceId', serviceId)
    formData.append('image', image)
    formData.append('name', name)

    return http
      .post<any>(`${apiUrl}/album/scr/serviceAlbum`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

interface UploadImageProps {
  albumId: string
  image: File
  imageName: string
}
export const uploadImageToAlbum: any = async ({
  albumId,
  image,
  imageName
}: UploadImageProps) => {
  if (image) {
    const formData = new FormData()

    formData.append('albumId', albumId)
    formData.append('image', image)
    formData.append('imageName', imageName)

    return http
      .post<any>(`${apiUrl}/album/scr/upload`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}
export const deleteImage: any = id =>
  http.delete<any>(`${apiUrl}/image/scr/deleteById`, {
    params: { id: id }
  })

interface UploadCoverAlbumProps {
  companyId: string
  image: File
}

export const uploadCoverAlbum: any = async ({
  companyId,
  image
}: UploadCoverAlbumProps) => {
  if (image) {
    const formData = new FormData()

    formData.append('companyId', companyId)
    formData.append('image', image)
    formData.append('name', `CoverAlbum - ${companyId}`)

    return http
      .post<any>(`${apiUrl}/album/scr/createCoverAlbum`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

export const deleteAlbum: any = id =>
  http.delete<any>(`${apiUrl}/album/scr/deleteById`, {
    params: { id }
  })

export const uploadSegmentImage: any = async ({
  id,
  file
}: {
  id: string
  file: File
}) => {
  if (file) {
    const formData = new FormData()

    formData.append('id', id)
    formData.append('image', file)
    return http
      .post<any>(`${apiUrl}/helpingSegment/scr/uploadIcon`, formData)
      .then(response => ({ data: { ...response.data } }))
  }
}

export default dataProvider
