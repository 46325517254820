import React from 'react'
import { TextField, Edit, SimpleForm, NumberInput } from 'react-admin'
import {
  Container,
  MarkdownInput,
  Section,
  Select
} from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import Title from '../shared/Title'

const ProductCatalogueEdit: React.FC<any> = props => (
  <Edit title={<Title title="Product catalogue" />} {...props}>
    <SimpleForm>
      <Container>
        <Section label="Edit product catalogue">
          <TextField source="id" />
          <Select
            source="productId"
            reference="product"
            validate={validation.required}
          />
          <NumberInput source="price" validate={validation.required} />
          <NumberInput source="discountPrice" />
          <MarkdownInput source="discountDescription" />
          <Select
            source="storeId"
            reference="store"
            validate={validation.required}
          />
        </Section>
      </Container>
    </SimpleForm>
  </Edit>
)

export default ProductCatalogueEdit
