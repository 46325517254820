interface RawAlbum {
  name: string
  coverImage: any
  images: any[]
}

interface Image {
  imageName: string
  image: File
}

interface Album {
  name: string
  coverImage: File
  images: Image[]
}

export const transformAlbums = (rawAlbums: RawAlbum[]): Album[] => {
  if (!rawAlbums || rawAlbums.length === 0) {
    return null
  }
  return rawAlbums?.map(rawAlbum => {
    return {
      name: rawAlbum?.name,
      coverImage: rawAlbum?.coverImage.rawFile,
      images: rawAlbum?.images.map(image => {
        return {
          imageName: image.title.split('.')[0],
          image: image.rawFile
        }
      })
    }
  })
}
