import React from 'react'
import { useFormState } from 'react-final-form'
import { useAsync } from 'react-use'
import {
  TextInput,
  SelectInput,
  ArrayInput,
  SimpleFormIterator,
  FormDataConsumer,
  regex,
  SelectArrayInput
} from 'react-admin'
import daysOfWeek from '../shared/daysOfWeek'
import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import dataProvider, {
  uploadCompanyAlbum,
  uploadImageToAlbum
} from 'esnafDataProvider'
import { AlbumSuccessProps } from 'types/domain'

export const AddressInput: React.FC<any> = () => (
  <>
    <TextInput
      label="City"
      source="address.city"
      validate={validation.required}
    />
    <TextInput
      label="County"
      source="address.county"
      validate={validation.required}
    />
    <TextInput
      label="Street"
      source="address.street"
      validate={validation.required}
    />
    <TextInput
      label="Number"
      source="address.number"
      validate={validation.required}
    />
    <TextInput label="Lat" source="address.lat" validate={validation.number} />
    <TextInput label="Lon" source="address.lon" validate={validation.number} />
  </>
)

export const WorkingDaysInput: React.FC<any> = () => {
  const validateTime = regex(
    /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9](:[0-5][0-9])?$/,
    'Must be a HH:MM time format'
  )

  return (
    <Section label="Working Days & Hours">
      <ArrayInput label="" source="workingHours">
        <SimpleFormIterator margin="dense">
          <FormDataConsumer>
            {({ getSource }) => {
              return (
                <>
                  <SelectInput
                    label="Day of week"
                    source={getSource('dayOfWeek')}
                    choices={daysOfWeek}
                    required
                  />
                  <TextInput
                    label="from"
                    source={getSource('from')}
                    validate={validateTime}
                    required
                  />
                  <TextInput
                    label="to"
                    source={getSource('to')}
                    validate={validateTime}
                    required
                  />
                </>
              )
            }}
          </FormDataConsumer>
        </SimpleFormIterator>
      </ArrayInput>
    </Section>
  )
}

export const SubCategoryInput = () => {
  const { values } = useFormState()
  const subCategories = useAsync(() =>
    dataProvider.getList('subCategory', {
      pagination: { page: 1, perPage: 10000 },
      sort: { field: 'id', order: 'ASC' }
    })
  )

  const data = subCategories.value?.data.filter(item =>
    values.categoryIds?.includes(item.categoryId)
  )
  return (
    <SelectArrayInput
      optionText="name"
      source="subCategoryIds"
      choices={values.categoryIds ? data : []}
    />
  )
}

export const companyAlbumOnSuccess = async ({
  data,
  parentId,
  type = 'normal'
}: AlbumSuccessProps) => {
  if (data && data.length > 0) {
    data?.map(async album => {
      const upladedAlbum = await uploadCompanyAlbum({
        companyId: parentId,
        image: album.coverImage,
        name: album.name,
        isComparison: type === 'comparison',
        is360: type === '360'
      })
      await album.images?.map(({ imageName, image }) =>
        uploadImageToAlbum({
          albumId: upladedAlbum.data.id,
          image,
          imageName
        })
      )
    })
  }
}
