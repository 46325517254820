import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton
} from 'react-admin'

const SeoList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'route', order: 'ASC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="route" />
      <TextField source="title" />
      <TextField source="description" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default SeoList
