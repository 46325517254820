import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  EditButton,
  DeleteWithConfirmButton
} from 'react-admin'

const ProductList: React.FC = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="manufacturerId" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default ProductList
