import React from 'react'
import { Typography, Box } from '@material-ui/core'

const Title: React.FC<any> = ({ prefix = 'Edit', record }) => {
  return <span>{`${prefix} ${record ? `"${record.name}"` : ''}`}</span>
}

export const SectionTitle: React.FC<any> = ({ label }: { label: string }) => (
  <Typography variant="h6" gutterBottom>
    {label}
  </Typography>
)

export const Separator: React.FC<any> = () => <Box pt="1em" />

export default Title
