import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  ReferenceField
} from 'react-admin'

const BannerList: React.FC = props => (
  <List {...props} bulkActionButtons={false} exporter={false}>
    <Datagrid rowClick="edit">
      <TextField source="bannerPosition" />
      <ReferenceField source="companyId" reference="company">
        <TextField source="name" />
      </ReferenceField>
      <ImageField source="url" title="Banner" />
    </Datagrid>
  </List>
)

export default BannerList
