import {
  Container,
  MarkdownInput,
  Section,
  Select
} from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import { Create, SimpleForm, NumberInput } from 'react-admin'

const ProductCatalogueCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Container>
        <Section label="Create product catalogue">
          <Select
            source="productId"
            reference="product"
            validate={validation.required}
          />
          <NumberInput source="price" validate={validation.required} />
          <NumberInput source="discountPrice" />
          <MarkdownInput source="discountDescription" />
          <Select
            source="storeId"
            reference="store"
            validate={validation.required}
          />
        </Section>
      </Container>
    </SimpleForm>
  </Create>
)

export default ProductCatalogueCreate
