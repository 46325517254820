import StoreList from './StoreList'
import StoreEdit from './StoreEdit'
import StoreCreate from './StoreCreate'
import StoreIcon from '@material-ui/icons/Store'

export default {
  list: StoreList,
  edit: StoreEdit,
  create: StoreCreate,
  icon: StoreIcon
}
