import React, { useRef } from 'react'
import {
  Edit,
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  useEditController
} from 'react-admin'
import {
  uploadCoverAlbum,
  uploadImageToAlbum,
  uploadLogo,
  uploadThumbnail
} from '../../esnafDataProvider'
import Title from '../shared/Title'
import {
  AddressInput,
  companyAlbumOnSuccess,
  SubCategoryInput,
  WorkingDaysInput
} from './shared'
import {
  AlbumsUpload,
  Container,
  MarkdownInput,
  MultiSelect,
  AlbumIdPreview,
  Section,
  Select
} from 'components/shared/Fields'
import { transformAlbums } from 'components/shared/functions'
import { validation } from 'components/shared/validations'
import { ImageUpload } from 'components/shared/ImageUpload'
import CoverAlbumUpload from 'components/shared/CoverAlbumUpload'

export const CompanyEdit: React.FC<any> = props => {
  const { record } = useEditController(props)

  const newAlbumsRef = useRef([])
  const coverAlbumRef = useRef([])

  return (
    <Edit
      undoable={false}
      title={<Title title="Company" />}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        coverAlbumRef.current = transformAlbums([data.coverAlbum])

        return { ...data, isRepairman: data.isRepairman ? true : false }
      }}
      onSuccess={async () => {
        await companyAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: props.id
        })
        if (coverAlbumRef.current) {
          coverAlbumRef.current?.map(async album => {
            const upladedAlbum = await uploadCoverAlbum({
              companyId: props.id,
              image: album.coverImage
            })
            await album.images?.map(({ imageName, image }) =>
              uploadImageToAlbum({
                albumId: upladedAlbum.data.id,
                image,
                imageName
              })
            )
          })
        }
      }}
      {...props}
    >
      <TabbedForm>
        <FormTab label="General">
          <Container>
            <Section label="General">
              <TextInput source="name" validate={validation.required} />
              <TextInput source="email" validate={validation.email} />
              <TextInput source="phoneNum" validate={validation.phone} />
              <TextInput source="webPageUrl" />
              <MultiSelect
                source="categoryIds"
                reference="category"
                validate={validation.required}
              />
              <SubCategoryInput />
              <BooleanInput source="isRepairman" />
              <TextInput source="videoUrl" />
            </Section>
            <Section label="Business">
              <TextInput source="pib" validate={validation.pib} />
              <BooleanInput source="promoted" />
              <BooleanInput source="hidden" label="Hide company" />
              <Select
                source="businessPackageId"
                reference="businessPackage"
                validate={validation.required}
              />
            </Section>
            <Section label="Address">
              <AddressInput />
            </Section>
          </Container>
        </FormTab>
        <FormTab label="Description">
          <Section label="Description" width={800}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
          </Section>
        </FormTab>
        <FormTab label="Images">
          <Container>
            <Section label="Logo">
              <ImageUpload
                source="logo"
                onChange={file => {
                  uploadLogo({ companyId: record.id, file })
                }}
                previewSource={record?.logoUrl}
              />
            </Section>
            <Section label="Thumbnail">
              <ImageUpload
                source="thumbnailFile"
                onChange={file => {
                  uploadThumbnail({ companyId: record.id, file })
                }}
                previewSource={record?.thumbnail}
              />
            </Section>
            <Section label="Cover album">
              {record?.coverAlbumId ? (
                <AlbumIdPreview
                  key={record.coverAlbumId}
                  albumId={record.coverAlbumId}
                />
              ) : (
                <CoverAlbumUpload source="coverAlbum" />
              )}
            </Section>
            <Section label="Albums">
              {record?.albumIds.map(id => (
                <AlbumIdPreview key={id} albumId={id} />
              ))}
              <AlbumsUpload source="newAlbums" />
            </Section>
          </Container>
        </FormTab>
        <FormTab label="Working days">
          <WorkingDaysInput />
        </FormTab>
        <FormTab label="Related companies">
          <Section label="Related companies">
            <MultiSelect source="distributorIds" reference="company" />
            <MultiSelect source="manufacturerIds" reference="company" />
            <MultiSelect source="partnerIds" reference="company" />
            <MultiSelect source="retailerIds" reference="company" />
          </Section>
        </FormTab>
      </TabbedForm>
    </Edit>
  )
}

export default CompanyEdit
