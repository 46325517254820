import { Container, Section, Select } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { AddressInput, WorkingDaysInput } from '../company/shared'
import Title from '../shared/Title'

export const StoreEdit: React.FC<any> = props => (
  <Edit undoable={false} title={<Title title="Store" />} {...props}>
    <SimpleForm>
      <Container>
        <Section label="General">
          <TextInput source="name" validate={validation.required} />
          <Select
            source="companyId"
            reference="company"
            validate={validation.required}
          />
        </Section>
        <Section label="Address">
          <AddressInput />
        </Section>
        <WorkingDaysInput />
      </Container>
    </SimpleForm>
  </Edit>
)

export default StoreEdit
