import React from 'react'
import { Card, CardContent, CardHeader } from '@material-ui/core'

const Dashboard: React.FC<any> = () => {
  return (
    <Card>
      <CardHeader title="Welcome to the Esnaf Admin!" />
      <CardContent>
        Kasnije mozemo ubaciti neki kontent ovde vezan za statistike aplikacije
        itd.
      </CardContent>
    </Card>
  )
}

export default Dashboard
