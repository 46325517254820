import { MultiSelect, Section, Select } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import Title from '../shared/Title'

const HelpingStepEdit: React.FC<any> = props => (
  <Edit undoable={false} title={<Title title="Category" />} {...props}>
    <SimpleForm>
      <Section label="Edit helping segment">
        <TextInput disabled source="id" />
        <TextInput source="name" validate={validation.required} />
        <MultiSelect source="companyIds" reference="company" />
        <MultiSelect source="productIds" reference="product" />
        <Select
          source="helpingSegmentId"
          reference="helpingSegment"
          validate={validation.required}
        />
      </Section>
    </SimpleForm>
  </Edit>
)

export default HelpingStepEdit
