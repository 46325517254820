import {
  AlbumsUpload,
  ComparisonsUpload,
  Container,
  Images360Upload,
  MarkdownInput,
  Section,
  Select
} from 'components/shared/Fields'
import { transformAlbums } from 'components/shared/functions'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import React, { useRef } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  NumberInput,
  useRedirect
} from 'react-admin'
import { uploadServiceImage } from '../../esnafDataProvider'
import { serviceAlbumOnSuccess } from './shared'

const ServiceCreate: React.FC<any> = props => {
  const redirect = useRedirect()

  const coverImageFileRef = useRef()
  const newAlbumsRef = useRef([])
  const comparisonImagesRef = useRef([])
  const images360Ref = useRef([])

  return (
    <Create
      undoable={false}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        comparisonImagesRef.current = transformAlbums(data.comparisonImages)
        images360Ref.current = transformAlbums(data.images360)

        return data
      }}
      onSuccess={async ({ data }) => {
        await uploadServiceImage({
          serviceId: data.id,
          file: coverImageFileRef.current
        })

        await serviceAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: data.id
        })
        await serviceAlbumOnSuccess({
          data: comparisonImagesRef.current,
          parentId: data.id,
          type: 'comparison'
        })
        await serviceAlbumOnSuccess({
          data: images360Ref.current,
          parentId: data.id,
          type: '360'
        })

        redirect('/service')
      }}
      {...props}
    >
      <SimpleForm>
        <Container>
          <Section label="General">
            <TextInput source="name" validate={validation.required} />
            <Select
              source="companyId"
              reference="company"
              validate={validation.required}
            />
            <NumberInput source="price" validate={validation.required} />
          </Section>
          <Section label="Description" width={600}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
          </Section>
          <Section label="Albums">
            <AlbumsUpload source="newAlbums" />
          </Section>
          <Section label="Before After images">
            <ComparisonsUpload source="comparisonImages" />
          </Section>
          <Section label="360 images">
            <Images360Upload source="images360" />
          </Section>
          <Section label="Cover Image">
            <ImageUpload
              source="coverImage"
              onChange={file => {
                coverImageFileRef.current = file
              }}
            />
          </Section>
        </Container>
      </SimpleForm>
    </Create>
  )
}

export default ServiceCreate
