import SeoList from './SeoList'
import SeoEdit from './SeoEdit'
import SeoCreate from './SeoCreate'
import CategoryIcon from '@material-ui/icons/Category'

export default {
  list: SeoList,
  edit: SeoEdit,
  create: SeoCreate,
  icon: CategoryIcon
}
