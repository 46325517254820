import { validation } from 'components/shared/validations'
import { uploadImageToAlbum, uploadProductAlbum } from 'esnafDataProvider'
import React from 'react'
import { TextInput, ArrayInput, SimpleFormIterator } from 'react-admin'
import { AlbumSuccessProps } from 'types/domain'

export const productStatus = [
  { id: 'REGULAR', name: 'Regular' },
  { id: 'PROMOTED', name: 'Promoted' }
]

export const TagsInput: React.FC<any> = () => (
  <ArrayInput source="tags" validate={validation.required}>
    <SimpleFormIterator>
      <TextInput label="name" source="name" validate={validation.required} />
    </SimpleFormIterator>
  </ArrayInput>
)

export const AttributesInput: React.FC<any> = () => (
  <ArrayInput source="attributes" validate={validation.required}>
    <SimpleFormIterator>
      <TextInput label="name" source="name" validate={validation.required} />
      <TextInput label="value" source="value" validate={validation.required} />
    </SimpleFormIterator>
  </ArrayInput>
)

export const productAlbumOnSuccess = async ({
  data,
  parentId,
  type = 'normal'
}: AlbumSuccessProps) => {
  if (data && data.length > 0) {
    data?.map(async album => {
      const upladedAlbum = await uploadProductAlbum({
        productId: parentId,
        image: album.coverImage,
        name: album.name,
        isComparison: type === 'comparison',
        is360: type === '360'
      })
      await album.images?.map(({ imageName, image }) =>
        uploadImageToAlbum({
          albumId: upladedAlbum.data.id,
          image,
          imageName
        })
      )
    })
  }
}
