import SubCategoryList from './SubCategoryList'
import SubCategoryEdit from './SubCategoryEdit'
import SubCategoryCreate from './SubCategoryCreate'
import CategoryOutlinedIcon from '@material-ui/icons/CategoryOutlined'

export default {
  list: SubCategoryList,
  edit: SubCategoryEdit,
  create: SubCategoryCreate,
  icon: CategoryOutlinedIcon
}
