import React from 'react'
import { MultiSelect, Section, Select } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import { SimpleForm, TextInput, Create } from 'react-admin'

const HelpingStepCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Section label="Create helping step">
        <TextInput source="name" validate={validation.required} />
        <MultiSelect source="companyIds" reference="company" />
        <MultiSelect source="productIds" reference="product" />
        <Select
          source="helpingSegmentId"
          reference="helpingSegment"
          validate={validation.required}
        />
      </Section>
    </SimpleForm>
  </Create>
)

export default HelpingStepCreate
