import React from 'react'
import {
  ArrayInput,
  Link,
  linkToRecord,
  ReferenceArrayInput,
  ReferenceInput,
  SelectArrayInput,
  SelectInput,
  SimpleFormIterator,
  TextInput
} from 'react-admin'
import { noop } from 'rxjs'
import { SectionTitle } from './Title'
import RichTextInput from 'ra-input-rich-text'
import { ImageUpload } from './ImageUpload'
import './Fields.css'

export const Select: React.FC<{
  source: string
  reference: string
  [key: string]: any
}> = props => (
  <ReferenceInput {...props} resource={props.reference}>
    <SelectInput optionText="name" {...props} />
  </ReferenceInput>
)

export const MultiSelect: React.FC<{
  source: string
  reference: string
  [key: string]: any
}> = ({ source, reference, ...rest }) => {
  return (
    <ReferenceArrayInput
      source={source}
      reference={reference}
      resource={reference}
      {...rest}
    >
      <SelectArrayInput optionText="name" {...rest} />
    </ReferenceArrayInput>
  )
}

export const AlbumsUpload: React.FC<{
  source: string
  onChange?(data): void
}> = ({ source, onChange = noop }) => {
  return (
    <ArrayInput source={source} onChange={onChange}>
      <SimpleFormIterator>
        <TextInput label="Album name" source="name" />
        <ImageUpload source="coverImage" label="Cover image" />
        <ImageUpload source="images" label="Images" multiple={true} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const ComparisonsUpload: React.FC<{
  source: string
  onChange?(data): void
}> = ({ source, onChange = noop }) => {
  return (
    <ArrayInput source={source} onChange={onChange}>
      <SimpleFormIterator>
        <TextInput label="Comparison Image name" source="name" />
        <ImageUpload source="coverImage" label="Cover image" />
        <ImageUpload source="images[0]" label="Before" />
        <ImageUpload source="images[1]" label="After" />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const Images360Upload: React.FC<{
  source: string
  onChange?(data): void
}> = ({ source, onChange = noop }) => {
  return (
    <ArrayInput source={source} onChange={onChange}>
      <SimpleFormIterator>
        <TextInput label="360 images name" source="name" />
        <ImageUpload source="coverImage" label="Cover image" />
        <ImageUpload source="images" label="Images" multiple={true} />
      </SimpleFormIterator>
    </ArrayInput>
  )
}

export const Container: any = ({ children }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap'
        // flexDirection: isSmall ? 'column' : 'row'
      }}
    >
      {children}
    </div>
  )
}

export const Section: any = ({ label = '', children, width = 400 }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: width,
        margin: 20,
        padding: 15,
        borderRadius: 10,
        border: '1px solid rgba(0,0,0,0.2)',
        boxShadow: '0 2px 2px 0 rgba(0,0,0,0.2)',
        height: '100%'
      }}
    >
      <SectionTitle label={label} />
      {children}
    </div>
  )
}

export const MarkdownInput: any = ({ source, ...props }) => (
  <RichTextInput source={source} {...props} />
)

export const AlbumIdPreview: any = ({ albumId }) => {
  const linkToUser = linkToRecord('/album', albumId, 'show')

  return <Link to={linkToUser}>{`Album - ${albumId}`}</Link>
}
