import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import { SimpleForm, TextInput, Create, NumberInput } from 'react-admin'

const BusinessPackageCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Section label="Create business package">
        <TextInput source="name" validate={validation.required} />
        <NumberInput source="imageUploadLimit" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Create>
)

export default BusinessPackageCreate
