import React from 'react'
import { FormDataConsumer, ImageField, ImageInput } from 'react-admin'
import { noop } from 'rxjs'
import './Fields.css'

interface Props {
  source: string
  label?: string
  multiple?: boolean
  onChange?(file): void
  previewSource?: string
}

export const ImageUpload: React.FC<Props> = ({
  source,
  label = null,
  multiple = false,
  onChange = noop,
  previewSource
}) => {
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        flexWrap: 'wrap'
      }}
    >
      <div style={{ width: '48%' }}>
        <ImageInput
          source={source}
          label={label}
          accept="image/*"
          multiple={multiple}
          onChange={onChange}
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </div>
      <div style={{ width: '48%', padding: '8px 0 4px' }}>
        {previewSource && (
          <FormDataConsumer>
            {({ formData }) =>
              !formData[source] && (
                <img
                  src={previewSource}
                  style={{
                    padding: '8px 0 4px',
                    height: '85px',
                    maxWidth: '100%'
                  }}
                  alt=""
                />
              )
            }
          </FormDataConsumer>
        )}
      </div>
    </div>
  )
}
