import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'
import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import Title from '../shared/Title'

const CategoryEdit: React.FC<any> = props => (
  <Edit undoable={false} title={<Title title="Category" />} {...props}>
    <SimpleForm>
      <Section label="Edit category">
        <TextInput disabled source="id" />
        <TextInput source="name" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Edit>
)

export default CategoryEdit
