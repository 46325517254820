import React, { useRef } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  SelectInput,
  useEditController,
  useRedirect
} from 'react-admin'
import Title from '../shared/Title'
import {
  AttributesInput,
  productAlbumOnSuccess,
  productStatus,
  TagsInput
} from './shared'
import {
  AlbumIdPreview,
  AlbumsUpload,
  ComparisonsUpload,
  Container,
  Images360Upload,
  MarkdownInput,
  MultiSelect,
  Section,
  Select
} from 'components/shared/Fields'
import { uploadProductImage } from 'esnafDataProvider'
import { transformAlbums } from 'components/shared/functions'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'

export const ProductEdit: React.FC<any> = props => {
  const { record } = useEditController(props)
  const redirect = useRedirect()

  const newAlbumsRef = useRef([])
  const comparisonImagesRef = useRef([])
  const images360Ref = useRef([])

  return (
    <Edit
      title={<Title title="Product" />}
      undoable={false}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        comparisonImagesRef.current = transformAlbums(data.comparisonImages)
        images360Ref.current = transformAlbums(data.images360)

        return data
      }}
      onSuccess={async () => {
        await productAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: props.id
        })
        await productAlbumOnSuccess({
          data: comparisonImagesRef.current,
          parentId: props.id,
          type: 'comparison'
        })
        await productAlbumOnSuccess({
          data: images360Ref.current,
          parentId: props.id,
          type: '360'
        })

        redirect('/product')
      }}
      {...props}
    >
      <SimpleForm>
        <Container>
          <Section label="General">
            <TextInput source="name" validate={validation.required} />
            <Select
              source="manufacturerId"
              reference="company"
              validate={validation.required}
            />
            <MultiSelect
              source="subCategoryIds"
              reference="subCategory"
              validate={validation.required}
            />
            <MultiSelect source="relatedProductIds" reference="product" />
            <TextInput source="videoUrl" />
            <SelectInput
              label="Status"
              source="status"
              choices={productStatus}
            />
          </Section>
          <Section label="Description" width={600}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
            <MarkdownInput
              source="shortDescription"
              validate={validation.required}
            />
          </Section>
          <Section label="Albums">
            {record?.albumIds.map(id => (
              <AlbumIdPreview key={id} albumId={id} />
            ))}
            <AlbumsUpload source="newAlbums" />
            <ComparisonsUpload source="comparisonImages" />
            <Images360Upload source="images360" />
          </Section>
          <Section label="Tags">
            <TagsInput />
          </Section>
          <Section label="Attributes">
            <AttributesInput />
          </Section>
          <Section label="Cover Image">
            <ImageUpload
              source="coverImageFile"
              onChange={file => {
                uploadProductImage({ productId: record.id, file })
              }}
              previewSource={record?.coverImageUrl}
            />
          </Section>
          <Section label="Pictogram">
            <ImageUpload
              source="pictogramFile"
              onChange={file => {
                uploadProductImage({
                  productId: record.id,
                  file,
                  imageType: 'pictogram'
                })
              }}
              previewSource={record?.pictogram}
            />
          </Section>
        </Container>
      </SimpleForm>
    </Edit>
  )
}
export default ProductEdit
