import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import {
  Edit,
  NumberInput,
  SimpleForm,
  TextField,
  TextInput
} from 'react-admin'
import Title from '../shared/Title'

const BusinessPackageEdit: React.FC<any> = props => (
  <Edit title={<Title title="Business package" />} {...props}>
    <SimpleForm>
      <Section label="Edit business package">
        <TextField source="id" />
        <TextInput source="name" validate={validation.required} />
        <NumberInput source="imageUploadLimit" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Edit>
)

export default BusinessPackageEdit
