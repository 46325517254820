import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  SingleFieldList,
  ChipField,
  ReferenceArrayField
} from 'react-admin'

const CategoryList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceArrayField
        label="SubCategories"
        reference="subCategory"
        source="subcategoryIds"
      >
        <SingleFieldList>
          <ChipField source="name" />
        </SingleFieldList>
      </ReferenceArrayField>
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default CategoryList
