import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  EditButton,
  DeleteWithConfirmButton,
  ReferenceField
} from 'react-admin'

const SubCategoryList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ReferenceField source="categoryId" reference="category">
        <TextField source="name" />
      </ReferenceField>
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default SubCategoryList
