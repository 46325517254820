import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ReferenceField,
  NumberField,
  EditButton,
  DeleteWithConfirmButton,
  BooleanField
} from 'react-admin'

const ProductCatalogueList: React.FC = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <ReferenceField label="Product" source="productId" reference="product">
        <TextField source="name" />
      </ReferenceField>
      <ReferenceField label="Store" source="storeId" reference="store">
        <TextField source="name" />
      </ReferenceField>
      <NumberField source="price" />
      <BooleanField source="onSale" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default ProductCatalogueList
