import { Container, Section, Select } from 'components/shared/Fields'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import React from 'react'
import { SimpleForm, Create, SelectInput } from 'react-admin'
import { bannerPosition } from './shared'

const BannerCreate: React.FC<any> = props => {
  return (
    <Create
      {...props}
      transform={data => {
        const formData = new FormData()

        formData.append('bannerPosition', data.bannerPosition)
        formData.append('companyId', data.companyId)
        formData.append('image', data.image.rawFile)

        return formData
      }}
    >
      <SimpleForm>
        <Container>
          <Section label="Create banner">
            <SelectInput
              label="Position"
              source="bannerPosition"
              choices={bannerPosition}
              validate={validation.required}
            />
            <Select
              source="companyId"
              reference="company"
              validate={validation.required}
            />
          </Section>
          <Section label="Cover Image">
            <ImageUpload source="image" />
          </Section>
        </Container>
      </SimpleForm>
    </Create>
  )
}

export default BannerCreate
