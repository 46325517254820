import CompanyList from './CompanyList'
import CompanyEdit from './CompanyEdit'
import CompanyCreate from './CompanyCreate'
import BusinessIcon from '@material-ui/icons/Business'

export default {
  list: CompanyList,
  edit: CompanyEdit,
  create: CompanyCreate,
  icon: BusinessIcon
}
