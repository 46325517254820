import React from 'react'
import { ImageUpload } from './ImageUpload'
import './Fields.css'

const CoverAlbumUpload: React.FC<{
  source: string
}> = ({ source }) => {
  return (
    <div>
      <ImageUpload source={`${source}.coverImage`} label="Cover image" />
      <ImageUpload source={`${source}.images`} label="Images" multiple={true} />
    </div>
  )
}

export default CoverAlbumUpload
