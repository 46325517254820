import React from 'react'
import { Edit, SimpleForm, TextInput, Title } from 'react-admin'
import { Section, Select } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'

const SubCategoryEdit: React.FC<any> = props => (
  <Edit undoable={false} title={<Title title="Subcategory" />} {...props}>
    <SimpleForm>
      <Section label="Edit subcategory">
        <TextInput disabled source="id" />
        <Select source="categoryId" reference="category" />
        <TextInput source="name" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Edit>
)

export default SubCategoryEdit
