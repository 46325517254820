import HelpingStepCreate from './HelpingStepCreate'
import HelpingStepEdit from './HelpingStepEdit'
import HelpingStepList from './HelpingStepList'
import GamesIcon from '@material-ui/icons/Games'

export default {
  list: HelpingStepList,
  edit: HelpingStepEdit,
  create: HelpingStepCreate,
  icon: GamesIcon
}
