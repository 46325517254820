import ServiceList from './ServiceList'
import ServiceEdit from './ServiceEdit'
import ServiceCreate from './ServiceCreate'
import RoomServiceIcon from '@material-ui/icons/RoomService'

export default {
  list: ServiceList,
  edit: ServiceEdit,
  create: ServiceCreate,
  icon: RoomServiceIcon
}
