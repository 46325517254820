import React from 'react'
import './App.css'
import { Admin, Resource } from 'react-admin'
import categoryActions from './components/category'
import seoActions from './components/seo'
import subCategoryActions from './components/subCategory'
import companyActions from './components/company'
import productActions from './components/product'
import serviceActions from './components/service'
import storeActions from './components/store'
import businessPackageActions from './components/businessPackage'
import productCatalogueActions from './components/productCatalogue'
import albumActions from './components/album'
import bannerActions from './components/banner'
import helpingSegmentActions from './components/helpingSegment'
import helpingStep from './components/helpingStep'
import Dashboard from './components/dashboard'
import authProvider from './authProvider'
import dataProvider from 'esnafDataProvider'

const App: React.FC = () => (
  <Admin
    disableTelemetry
    dashboard={Dashboard}
    authProvider={authProvider}
    dataProvider={dataProvider}
  >
    <Resource name="category" {...categoryActions} />
    <Resource name="subCategory" {...subCategoryActions} />
    <Resource name="company" {...companyActions} />
    <Resource name="product" {...productActions} />
    <Resource
      options={{ label: 'Product catalogues' }}
      name="productCatalogue"
      {...productCatalogueActions}
    />
    <Resource name="service" {...serviceActions} />
    <Resource name="store" {...storeActions} />
    <Resource name="album" {...albumActions} />
    <Resource
      options={{ label: 'Business packages' }}
      name="businessPackage"
      {...businessPackageActions}
    />
    <Resource name="helpingSegment" {...helpingSegmentActions} />
    <Resource name="step" {...helpingStep} />
    <Resource name="banner" {...bannerActions} />
    <Resource name="pageConfig" options={{ label: 'Seo' }} {...seoActions} />
  </Admin>
)

export default App
