import { http } from 'esnafDataProvider'
import { BehaviorSubject } from 'rxjs'

const AUTH_URL = process.env.REACT_APP_AUTH_URL
export const AUTH_HEADER = 'Authorization'

export const user$ = new BehaviorSubject<User>(null)

export interface User {
  email: string
  accessToken: string
  refreshToken: string
  plainToken: string
}

export interface AuthCredentials {
  username: string
  password: string
}

interface AuthResponseDto {
  id: number
  fullName: string
  access_token: string
  refresh_token: string
}

const authRequest: any = async (
  formData: FormData
): Promise<Omit<User, 'email'>> => {
  const { data } = await http.post<AuthResponseDto>(AUTH_URL, formData, {
    headers: {
      [AUTH_HEADER]: `Basic ${Buffer.from(`esnaf:esnaf`).toString('base64')}`
    }
  })

  return {
    accessToken: 'Bearer ' + data.access_token,
    refreshToken: data.refresh_token,
    plainToken: data.access_token
  }
}

export const refreshToken: any = async () => {
  const storageUser = JSON.parse(await localStorage.getItem('user'))

  const formData = new FormData()

  formData.append('grant_type', 'refresh_token')
  formData.append('refresh_token', storageUser.refreshToken)

  const user = await authRequest(formData)
  return user
}

const authProvider: any = {
  // called when the user attempts to log in
  login: async (credentials: AuthCredentials) => {
    const formData = new FormData()
    formData.append('grant_type', 'password')
    formData.append('username', credentials.username)
    formData.append('password', credentials.password)
    const user = await authRequest(formData)
    await localStorage.setItem(
      'user',
      JSON.stringify({ ...user, email: credentials.username })
    )
    http.defaults.headers['Authorization'] = user.accessToken

    return user
  },
  logout: () => {
    user$.next(null)
    localStorage.removeItem('user')
    return Promise.resolve()
  },
  // called when the API returns an error
  checkError: error => {
    const status = error.response?.status
    if (status === 401) {
      localStorage.removeItem('user')
      return Promise.reject()

      // refreshToken().then(user => {
      //   http.defaults.headers['Authorization'] = user.accessToken
      //   return localStorage.setItem('user', JSON.stringify(user))
      // })
    }
    if (status === 403) {
      localStorage.removeItem('user')

      return Promise.reject()
    }
    return Promise.resolve()
  },
  // called when the user navigates to a new location, to check for authentication
  checkAuth: () => {
    return localStorage.getItem('user') ? Promise.resolve() : Promise.reject()
  },
  // called when the user navigates to a new location, to check for permissions / roles
  getPermissions: () => Promise.resolve()
}

export default authProvider
