import React from 'react'
import { SimpleForm, TextInput, Create } from 'react-admin'
import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'

const CategoryCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Section label="Create category">
        <TextInput source="name" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Create>
)

export default CategoryCreate
