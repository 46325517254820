import React from 'react'
import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import { SimpleForm, TextInput, Create, SelectInput } from 'react-admin'

const routePaths = [
  { id: 'POCETNA', name: 'Početna' },
  { id: 'FIRME', name: '/firme' },
  { id: 'MAJSTORI', name: '/majstori' },
  { id: 'PROIZVODI', name: '/proizvodi' },
  { id: 'USLUGE', name: '/usluge' },
  { id: 'PRIVATNOST', name: '/privatnost' },
  { id: 'USLOVI', name: '/uslovi' },
  { id: 'PAKETI', name: '/paketi' },
  { id: 'DODAJ_KOMPANIJU', name: '/dodaj-kompaniju' },
  { id: 'O_NAMA', name: '/o-nama' },
  { id: 'KONTAKT', name: '/kontakt' },
  { id: 'MARKETING', name: '/marketing' }
]

const SeoCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Section label="Add Seo for page">
        <SelectInput
          label="route"
          source="route"
          choices={routePaths}
          required
        />
        <TextInput source="title" validate={validation.required} />
        <TextInput
          source="description"
          multiline
          validate={validation.required}
        />
      </Section>
    </SimpleForm>
  </Create>
)

export default SeoCreate
