import ProductCatalogueList from './ProductCatalogueList'
import ProductCatalogueEdit from './ProductCatalogueEdit'
import ProductCatalogueCreate from './ProductCatalogueCreate'
import DonutSmallIcon from '@material-ui/icons/DonutSmall'

export default {
  list: ProductCatalogueList,
  edit: ProductCatalogueEdit,
  create: ProductCatalogueCreate,
  icon: DonutSmallIcon
}
