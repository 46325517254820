import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  ImageField,
  EditButton,
  DeleteWithConfirmButton
} from 'react-admin'

const AlbumList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <ImageField source="coverImageUrl" title="CoverImage" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default AlbumList
