import { Section } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'
import React from 'react'
import { Edit, SimpleForm, TextInput } from 'react-admin'

const SeoEdit: React.FC<any> = props => (
  <Edit undoable={false} title="Edit" {...props}>
    <SimpleForm>
      <Section label="Edit Seo for page">
        <TextInput disabled source="route" />
        <TextInput source="title" validate={validation.required} />
        <TextInput
          source="description"
          multiline
          validate={validation.required}
        />
      </Section>
    </SimpleForm>
  </Edit>
)

export default SeoEdit
