import React from 'react'
import { SimpleForm, TextInput, Create } from 'react-admin'
import { Section, Select } from 'components/shared/Fields'
import { validation } from 'components/shared/validations'

const SubCategoryCreate: React.FC<any> = props => (
  <Create {...props}>
    <SimpleForm>
      <Section label="Create subcategory">
        <Select
          source="categoryId"
          reference="category"
          validate={validation.required}
        />
        <TextInput source="name" validate={validation.required} />
      </Section>
    </SimpleForm>
  </Create>
)

export default SubCategoryCreate
