import ProductList from './ProductList'
import ProductEdit from './ProductEdit'
import ProductCreate from './ProductCreate'
import DonutLargeIcon from '@material-ui/icons/DonutLarge'

export default {
  list: ProductList,
  edit: ProductEdit,
  create: ProductCreate,
  icon: DonutLargeIcon
}
