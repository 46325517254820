import {
  MultiSelect,
  Select,
  AlbumsUpload,
  Section,
  Container,
  MarkdownInput,
  ComparisonsUpload,
  Images360Upload
} from 'components/shared/Fields'
import { transformAlbums } from 'components/shared/functions'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import { uploadProductImage } from 'esnafDataProvider'
import React, { useRef } from 'react'
import {
  Create,
  SimpleForm,
  TextInput,
  SelectInput,
  useRedirect
} from 'react-admin'
import {
  AttributesInput,
  productAlbumOnSuccess,
  productStatus,
  TagsInput
} from './shared'

export const ProductCreate: React.FC<any> = props => {
  const redirect = useRedirect()

  const coverImageFileRef = useRef()
  const pictogramFileRef = useRef()
  const newAlbumsRef = useRef([])
  const comparisonImagesRef = useRef([])
  const images360Ref = useRef([])

  return (
    <Create
      undoable={false}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        comparisonImagesRef.current = transformAlbums(data.comparisonImages)
        images360Ref.current = transformAlbums(data.images360)
        return data
      }}
      onSuccess={async ({ data }) => {
        await uploadProductImage({
          productId: data.id,
          file: coverImageFileRef.current
        })

        await uploadProductImage({
          productId: data.id,
          file: pictogramFileRef.current,
          imageType: 'pictogram'
        })
        await productAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: data.id
        })
        await productAlbumOnSuccess({
          data: comparisonImagesRef.current,
          parentId: data.id,
          type: 'comparison'
        })
        await productAlbumOnSuccess({
          data: images360Ref.current,
          parentId: data.id,
          type: '360'
        })

        redirect('/product')
      }}
      {...props}
    >
      <SimpleForm>
        <Container>
          <Section label="General">
            <TextInput source="name" validate={validation.required} />
            <Select
              source="manufacturerId"
              reference="company"
              validate={validation.required}
            />
            <MultiSelect
              source="subCategoryIds"
              reference="subCategory"
              validate={validation.required}
            />
            <MultiSelect source="relatedProductIds" reference="product" />
            <TextInput source="videoUrl" />
            <SelectInput
              label="Status"
              source="status"
              choices={productStatus}
            />
          </Section>
          <Section label="Description" width={600}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
            <MarkdownInput
              source="shortDescription"
              validate={validation.required}
            />
          </Section>

          <Section label="Cover album">
            <AlbumsUpload source="newAlbums" />
          </Section>
          <Section label="Before After images">
            <ComparisonsUpload source="comparisonImages" />
          </Section>
          <Section label="360 images">
            <Images360Upload source="images360" />
          </Section>
          <Section label="Tags">
            <TagsInput />
          </Section>
          <Section label="Attributes">
            <AttributesInput />
          </Section>

          <Section label="Cover Image">
            <ImageUpload
              source="coverImage"
              onChange={file => {
                coverImageFileRef.current = file
              }}
            />
          </Section>
          <Section label="Pictogram">
            <ImageUpload
              source="pictogram"
              onChange={file => {
                pictogramFileRef.current = file
              }}
            />
          </Section>
        </Container>
      </SimpleForm>
    </Create>
  )
}

export default ProductCreate
