import {
  AlbumIdPreview,
  AlbumsUpload,
  ComparisonsUpload,
  Container,
  Images360Upload,
  MarkdownInput,
  Section,
  Select
} from 'components/shared/Fields'
import { transformAlbums } from 'components/shared/functions'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import { uploadServiceImage } from 'esnafDataProvider'
import React, { useRef } from 'react'
import {
  Edit,
  SimpleForm,
  TextInput,
  NumberInput,
  useEditController,
  useRedirect
} from 'react-admin'
import Title from '../shared/Title'
import { serviceAlbumOnSuccess } from './shared'

const ServiceEdit: React.FC<any> = props => {
  const { record } = useEditController(props)
  const redirect = useRedirect()
  const newAlbumsRef = useRef([])
  const comparisonImagesRef = useRef([])
  const images360Ref = useRef([])

  return (
    <Edit
      title={<Title title="Service" />}
      undoable={false}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        comparisonImagesRef.current = transformAlbums(data.comparisonImages)
        images360Ref.current = transformAlbums(data.images360)

        return data
      }}
      onSuccess={async () => {
        await serviceAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: props.id
        })
        await serviceAlbumOnSuccess({
          data: comparisonImagesRef.current,
          parentId: props.id,
          type: 'comparison'
        })
        await serviceAlbumOnSuccess({
          data: images360Ref.current,
          parentId: props.id,
          type: '360'
        })
        redirect('/service')
      }}
      {...props}
    >
      <SimpleForm>
        <Container>
          <Section label="General">
            <TextInput source="name" validate={validation.required} />
            <Select
              source="companyId"
              reference="company"
              validate={validation.required}
            />
            <NumberInput source="price" validate={validation.required} />
          </Section>
          <Section label="Description" width={600}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
          </Section>
          <Section label="Albums">
            {record?.albumIds.map(id => (
              <AlbumIdPreview key={id} albumId={id} />
            ))}
            <AlbumsUpload source="newAlbums" />
            <ComparisonsUpload source="comparisonImages" />
            <Images360Upload source="images360" />
          </Section>
          <Section label="Cover Image">
            <ImageUpload
              source="coverImageFile"
              onChange={file => {
                uploadServiceImage({ serviceId: record.id, file })
              }}
              previewSource={record?.coverImageUrl}
            />
          </Section>
        </Container>
      </SimpleForm>
    </Edit>
  )
}

export default ServiceEdit
