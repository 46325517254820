import HelpingSegmentCreate from './HelpingSegmentCreate'
import HelpingSegmentEdit from './HelpingSegmentEdit'
import HelpingSegmentList from './HelpingSegmentList'
import DoubleArrowIcon from '@material-ui/icons/DoubleArrow'

export default {
  list: HelpingSegmentList,
  edit: HelpingSegmentEdit,
  create: HelpingSegmentCreate,
  icon: DoubleArrowIcon
}
