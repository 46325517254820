import BusinessPackageList from './BusinessPackageList'
import BusinessPackageEdit from './BusinessPackageEdit'
import BusinessPackageCreate from './BusinessPackageCreate'
import BusinessCenterIcon from '@material-ui/icons/BusinessCenter'

export default {
  list: BusinessPackageList,
  edit: BusinessPackageEdit,
  create: BusinessPackageCreate,
  icon: BusinessCenterIcon
}
