import { MarkdownInput, Section } from 'components/shared/Fields'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import { uploadSegmentImage } from 'esnafDataProvider'
import React from 'react'
import { Edit, SimpleForm, TextInput, useEditController } from 'react-admin'
import Title from '../shared/Title'

const HelpingSegmentEdit: React.FC<any> = props => {
  const { record } = useEditController(props)

  return (
    <Edit undoable={false} title={<Title title="Category" />} {...props}>
      <SimpleForm>
        <Section label="Edit helping segment">
          <TextInput disabled source="id" />
          <TextInput source="name" validate={validation.required} />
          <MarkdownInput source="description" validate={validation.required} />
          {/* <MultiSelect source="stepIds" reference="step" /> */}
        </Section>
        <Section label="Icon">
          <ImageUpload
            source="iconFile"
            onChange={file => {
              uploadSegmentImage({
                id: record.id,
                file
              })
            }}
            previewSource={record?.iconUrl}
          />
        </Section>
      </SimpleForm>
    </Edit>
  )
}

export default HelpingSegmentEdit
