import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  EditButton,
  DeleteWithConfirmButton
} from 'react-admin'

const StoreList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <BooleanField source="isOpen" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)
export default StoreList
