import React, { useRef } from 'react'
import {
  Create,
  TextInput,
  BooleanInput,
  TabbedForm,
  FormTab,
  useRedirect
} from 'react-admin'
import {
  uploadImageToAlbum,
  uploadCoverAlbum,
  uploadLogo,
  uploadThumbnail
} from '../../esnafDataProvider'
import {
  AddressInput,
  companyAlbumOnSuccess,
  SubCategoryInput,
  WorkingDaysInput
} from './shared'
import {
  AlbumsUpload,
  Container,
  MarkdownInput,
  MultiSelect,
  Section,
  Select
} from 'components/shared/Fields'
import { transformAlbums } from 'components/shared/functions'
import { validation } from 'components/shared/validations'
import { ImageUpload } from 'components/shared/ImageUpload'
import CoverAlbumUpload from 'components/shared/CoverAlbumUpload'

export const CompanyCreate: React.FC<any> = props => {
  const redirect = useRedirect()

  const logoFileRef = useRef()
  const thumbnailFileRef = useRef()
  const newAlbumsRef = useRef([])
  const coverAlbumRef = useRef([])

  return (
    <Create
      undoable={false}
      transform={data => {
        newAlbumsRef.current = transformAlbums(data.newAlbums)
        coverAlbumRef.current = transformAlbums([data.coverAlbum])
        return data
      }}
      onSuccess={async ({ data }) => {
        await uploadLogo({ companyId: data.id, file: logoFileRef.current })
        await uploadThumbnail({
          companyId: data.id,
          file: thumbnailFileRef.current
        })
        await companyAlbumOnSuccess({
          data: newAlbumsRef.current,
          parentId: data.id
        })

        if (coverAlbumRef.current) {
          coverAlbumRef.current?.map(async album => {
            const upladedAlbum = await uploadCoverAlbum({
              companyId: data.id,
              image: album.coverImage
            })
            await album.images?.map(({ imageName, image }) =>
              uploadImageToAlbum({
                albumId: upladedAlbum.data.id,
                image,
                imageName
              })
            )
          })
        }
        redirect('/company')
      }}
      {...props}
    >
      <TabbedForm>
        <FormTab label="General">
          <Container>
            <Section label="General">
              <TextInput source="name" validate={validation.required} />
              <TextInput source="email" validate={validation.email} />
              <TextInput source="phoneNum" validate={validation.phone} />
              <TextInput source="webPageUrl" />
              <MultiSelect
                source="categoryIds"
                reference="category"
                validate={validation.required}
              />
              <SubCategoryInput />
              <BooleanInput source="isRepairman" defaultValue={false} />
              <TextInput source="videoUrl" />
            </Section>
            <Section label="Business">
              <TextInput source="pib" validate={validation.pib} />
              <BooleanInput source="promoted" defaultValue={false} />
              <BooleanInput source="hidden" label="Hide company" />
              <Select
                source="businessPackageId"
                reference="businessPackage"
                validate={validation.required}
              />
            </Section>
            <Section label="Address">
              <AddressInput />
            </Section>
          </Container>
        </FormTab>
        <FormTab label="Description">
          <Section label="Description" width={800}>
            <MarkdownInput
              source="description"
              validate={validation.required}
            />
          </Section>
        </FormTab>
        <FormTab label="Images">
          <Container>
            <Section label="Logo">
              <ImageUpload
                source="logo"
                onChange={file => {
                  logoFileRef.current = file
                }}
              />
            </Section>
            <Section label="Thumbnail">
              <ImageUpload
                source="thumbnail"
                onChange={file => {
                  thumbnailFileRef.current = file
                }}
              />
            </Section>
            <Section label="Cover album">
              <CoverAlbumUpload source="coverAlbum" />
            </Section>
            <Section label="Albums">
              <AlbumsUpload source="newAlbums" />
            </Section>
          </Container>
        </FormTab>

        <FormTab label="Working days">
          <WorkingDaysInput />
        </FormTab>

        <FormTab label="Related companies">
          <Section label="Related companies">
            <MultiSelect source="distributorIds" reference="company" />
            <MultiSelect source="manufacturerIds" reference="company" />
            <MultiSelect source="partnerIds" reference="company" />
            <MultiSelect source="retailerIds" reference="company" />
          </Section>
        </FormTab>
      </TabbedForm>
    </Create>
  )
}

export default CompanyCreate
