import { MarkdownInput, Section } from 'components/shared/Fields'
import { ImageUpload } from 'components/shared/ImageUpload'
import { validation } from 'components/shared/validations'
import { uploadSegmentImage } from 'esnafDataProvider'
import React, { useRef } from 'react'
import { SimpleForm, TextInput, Create } from 'react-admin'

const HelpingSegmentCreate: React.FC<any> = props => {
  const iconFileRef = useRef()

  return (
    <Create
      undoable={false}
      onSuccess={async ({ data }) => {
        await uploadSegmentImage({ id: data.id, file: iconFileRef.current })
      }}
      {...props}
    >
      <SimpleForm>
        <Section label="Create helping segment">
          <TextInput source="name" validate={validation.required} />
          <MarkdownInput source="description" validate={validation.required} />
          {/* <MultiSelect source="stepIds" reference="step" /> */}
        </Section>
        <Section label="Icon">
          <ImageUpload
            source="iconUrl"
            onChange={file => {
              iconFileRef.current = file
            }}
          />
        </Section>
      </SimpleForm>
    </Create>
  )
}

export default HelpingSegmentCreate
