import React from 'react'
import {
  List,
  Datagrid,
  TextField,
  BooleanField,
  NumberField,
  EditButton,
  DeleteWithConfirmButton
} from 'react-admin'

export const CompanyList: React.FC<any> = props => (
  <List
    {...props}
    bulkActionButtons={false}
    exporter={false}
    sort={{ field: 'id', order: 'DESC' }}
  >
    <Datagrid rowClick="edit">
      <TextField source="id" />
      <TextField source="name" />
      <TextField source="email" />
      <TextField source="phoneNum" />
      <BooleanField source="promoted" />
      <NumberField label="Rating" source="rating.average" />
      <EditButton />
      <DeleteWithConfirmButton />
    </Datagrid>
  </List>
)

export default CompanyList
